/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'jquery/dist/jquery.min';
import 'popper.js/dist/popper';
import 'bootstrap/dist/js/bootstrap.min';